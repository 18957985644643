import Select from "react-select";
import styles from "../SignListView.module.css";
import { twMerge } from "tailwind-merge";

import { variantStyleMap } from "./Input";
import { forwardRef, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

const selectCustomStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "0.375rem",
    height: "2.625rem",
    border: "solid 2px rgb(229 231 235 / 1);",
    transition: "all 0.3s ease",
    ":hover": {
      ...styles[":active"],
      border: "solid 2px #818cf8;",
    },
    ":focus": {
      ...styles[":focus"],
      border: "solid 2px #818cf8;",
    },
  }),
};
export const SelectInput = forwardRef(
  ({ options = [], className, variant, form, id, ...props }, ref) => {
    const handleChange = (value) => {
      form.setValue(id, value);
    };
    return (
      <Select
        ref={ref}
        options={options}
        className={twMerge(
          `${styles.reactSelectInputFix} w-full text-left hover:border-indigo-400 focus:border-indigo-400`,
          variantStyleMap[variant],
          className
        )}
        styles={selectCustomStyles}
        onChange={handleChange}
        {...props}
      ></Select>
    );
  }
);

export const SelectForm = forwardRef(
  (
    {
      options = [],
      label,
      id,
      className,
      form,
      placeholder = "Selecionar opção",
      propValue = "value",
      propLabel = "label",
      onChange,
      ...props
    },
    ref
  ) => {
    const {
      control,
      register,
      trigger,
      formState: { errors, defaultValues },
    } = form;

    function findOption(optionValue) {
      return options.find((x) => x[propValue] == optionValue);
    }

    async function handleChange(event) {
      const value = event.target.value;
      console.log(value);
      form.setValue(id, value);
      trigger(id);
      onChange && onChange(event);
    }
    return (
      <div className="mb-4 text-sm flex-1">
        {label && <label className="font-semibold">{label}</label>}
        {/* <Select
          ref={ref}
          placeholder={placeholder}
          options={options}
          className={twMerge(
            `${styles.reactSelectInputFix} w-full text-left hover:border-indigo-400 focus:border-indigo-400`,
            className
          )}
          styles={selectCustomStyles}
          onChange={(value) => handleChange(value)}
          defaultValue={defaultValue}
          {...props}
        ></Select> */}
        <Controller
          name={id}
          control={control}
          defaultValue={defaultValues ? defaultValues[id] : null}
          render={({ field }) => (
            <select
              className={twMerge(
                `w-full block rounded-md border-gray-200 shadow-sm border-2 border-solid ease duration-300  hover:border-indigo-400 focus:border-indigo-400 focus:outline-0 focus:ring-0`,
                className
              )}
              placeholder={placeholder}
              styles={selectCustomStyles}
              {...field}
              onChange={handleChange}
            >
              <option value="">{placeholder}</option>
              {options.map((item) => (
                <option value={item[propValue]} key={item[propValue]}>
                  {item[propLabel]}
                </option>
              ))}
            </select>
          )}
        />

        {/* <select
          ref={ref}
          onChange={handleChange}
          className={twMerge(
            `w-full block rounded-md border-gray-200 shadow-sm border-2 border-solid ease duration-300  hover:border-indigo-400 focus:border-indigo-400 focus:outline-0 focus:ring-0`,
            className
          )}
          placeholder={placeholder}
          styles={selectCustomStyles}
          {...props}
        >
         
          {options.map((item) => (
            <option value={item[propValue]}> {item[propLabel]}</option>
          ))}
        </select> */}

        <small className="text-red-600">{errors[id]?.message}</small>
      </div>
    );
  }
);
