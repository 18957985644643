import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Pages/App';
import NotFound from './Pages/NotFound/NotFoundPage'
import TokenPage from './Pages/TokenPage/TokenPage';
import SignedPage from './Pages/Signed/SignedPage'
import DocumentListViewPage from './Pages/DocumentListView/DocumentListViewPage'
import LoginPage from './Pages/Login/Login'
import RegisterPage from './Pages/Register/RegisterPage'
import NewPasswordPage from './Pages/NewPassword/NewPasswordPage'
import SignListViewPage from './Pages/SignListView/SignListViewPage';
import TokenListViewPage from './Pages/TokenListView/TokenListViewPage';
import ResetPassworkPage from './Pages/NewPassword/ResetPasswordPage';
import SignaturesListViewPage from './Pages/Signatures/SignaturesListViewPage';
import 'react-date-range/dist/styles.css'; // Css data range picker
import 'react-date-range/dist/theme/default.css'; // Css data range picker
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UploadContract from "./Pages/UploadContract/UploadContract";
import {
    BrowserRouter as Router,
    useRoutes,
} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));

const NavigationRoutes = () => {
    let routes = useRoutes([
        { path: "/", element: <App /> },
        { path: "document-view-list", element: <DocumentListViewPage /> },
        { path: "signed", element: <SignedPage /> },
        { path: "token-validation", element: <TokenPage /> },
        { path: "notfound", element: <NotFound /> },
        { path: "login", element: <LoginPage /> },
        { path: "register", element: <RegisterPage /> },
        { path: "new-password", element: <NewPasswordPage /> },
        { path: "signs", element: <SignListViewPage /> },
        { path: "reset-password", element: <ResetPassworkPage /> },
        { path: "signatures", element: <SignaturesListViewPage /> },
        { path: "upload-contract", element: <UploadContract /> },
        // ...
    ]);
    return routes;
};

root.render(
    <Router>
        <NavigationRoutes />
        <ToastContainer />
    </Router>
);