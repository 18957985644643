import React, { createContext, useState, useContext, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


// Criação do contexto
const AuthContext = createContext();

// Provedor do contexto
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const isAuthenticated = () => {
    const token = localStorage.getItem('business-token');
    if (!token) {
      toast("Sessão expirada", {
        type: 'error',
        autoClose: 2000,
        theme: 'colored'
      })
      setTimeout(() => {
        navigate('/login')
      }, 2000)
      return false;
    }
    return true;
  }

  const login = () => {
    // Implementar login
  };

  // Função para fazer o logout
  const logout = () => {
    localStorage.removeItem('business-token');
    toast("Sessão expirada", {
      type: 'error',
      autoClose: 2000,
      theme: 'colored'
    })
    setTimeout(() => {
      navigate('/login')
    }, 2000)
  };

  // Objeto com os métodos e valores do contexto
  const authContextValue = {
    isAuthenticated,
    user,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};


// Hook customizado para utilizar o contexto
const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }

  return context;
};

export { AuthContext, AuthProvider, useAuth };
