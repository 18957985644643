import { twMerge } from "tailwind-merge";
import InputMask from "react-input-mask";
import { useState, useRef, forwardRef } from "react";
import { Controller } from "react-hook-form";
export let variantStyleMap = {
  md: "py-2 text-md",
  sm: "py-[.375rem] text-sm",
};

export const Input = forwardRef(
  (
    { type = "text", className, variant = "md", mask = null, ...props },
    ref
  ) => {
    return (
      <>
        {mask ? (
          <InputMask
            ref={ref}
            type={type}
            mask={mask}
            className={twMerge(
              "w-full block rounded-md border-gray-200 shadow-sm border-2 border-solid ease duration-300  hover:border-indigo-400 focus:border-indigo-400 focus:outline-0 focus:ring-0",
              variantStyleMap[variant],
              className
            )}
            {...props}
          />
        ) : (
          <input
            ref={ref}
            type={type}
            className={twMerge(
              "w-full block rounded-md border-gray-200 shadow-sm border-2 border-solid ease duration-300  hover:border-indigo-400 focus:border-indigo-400 focus:outline-0 focus:ring-0",
              variantStyleMap[variant],
              className
            )}
            {...props}
          />
        )}
      </>
    );
  }
);

export function InputGroup({
  label,
  id,
  type = "text",
  placeholder,
  form,
  mask = null,
  variant = "md",
  preserveMask = false,
  onChange,
  className = "",
  ...props
}) {
  const {
    control,
    register,
    trigger,
    formState: { errors, defaultValues },
  } = form;

  const handleChange = (event) => {
    let value = event.target.value;
    if (!preserveMask) {
      value = value.replace(/[^\d]/g, "");
    }
    form.setValue(id, value);
    trigger(id);
    onChange && onChange(event);
  };

  return (
    <div className="mb-4 text-sm flex-1">
      {label && <label className="font-semibold">{label}</label>}
      <Controller
        name={id}
        control={control}
        defaultValue={defaultValues ? defaultValues[id] : ""}
        render={({ field }) => (
          <Input
            type={type}
            placeholder={placeholder}
            mask={mask}
            className={className}
            {...props}
            onChange={handleChange}
            {...field}
          />
        )}
      />
      <small className="text-red-600">{errors[id]?.message}</small>
    </div>
  );
}
