import React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApprovalTokenValidation } from "../../Components/TokenInput";
import { useEffect } from "react";
import { useState } from "react";
import ErrorMessage from "../../Components/ErrorMessage";
import { InfoMessage } from "../../Components/InfoMessage";
import { LogoLayout } from '../../Components/LogoLayout';

const baseUrl = process.env.REACT_APP_API_URL;

function TokenPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);
    const [hasDocumentSigner, setHasDocumentSigner] = useState(false);
    const [hasError, setHasError] = useState(false);

    const hash = searchParams.get('hash');



    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    useEffect(() => {

           // chama função de assinar
        fetch(baseUrl + 'certification/has-signer-documents?hash=' + hash, requestOptions)
            .then((result) => {
                    if (result.status === 200) {
                        result.json().then(value => {
                            setHasDocumentSigner(value);
                            setLoading(false);
                        });
                        setHasError(false);
                        return;
                    }
                    else if (result.status === 404) {
                        navigate('/notfound');
                        return;
                    }

                    setHasError(true);
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                    setHasError(true);
                    setLoading(false);
                }
            )
    });

    const validateFunc = (hash, token, callback) => {
        navigate('/document-view-list?hash=' + hash);
        callback();
        localStorage.setItem("token", token);
    }

    return (
        <LogoLayout>
            {loading && (
                <div className="flex flex-col items-center justify-center mt-10">
                <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                </div>        
            )}
    
            {(!loading && !hasDocumentSigner && !hasError) ? (
                <div className="flex flex-col items-center justify-center mt-5">
                    <InfoMessage title="Documentos já assinados." message="Os documentos já foram assinados, aguarde a finalização do fluxo. Você receberá uma cópia dos documentos assinados." /> 
                </div>
            ) : ((!loading && !hasError) && <ApprovalTokenValidation secondsRequestTokenDisabled={240} validateCallback={validateFunc} hash={hash} signature={true} />)
            }

            {hasError && <ErrorMessage title="Erro informações de assinatura"  msg="Não foi possível obter informações para assinatura." />}
        </LogoLayout>
    );
}

export default TokenPage;