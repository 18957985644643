
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import Header from "../Components/Header";
import { useSearchParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

function App() {

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      // load da tela
      navigate('/login');

    }, 500);


  });



  return (
    <div className="bg-white py-24 sm:py-32 lg:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="sm:text-center">
          <Header></Header>
        </div>
      </div>
    </div>
  );
}

export default App;