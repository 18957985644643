import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import ErrorMessage from "../../Components/ErrorMessage";

const baseUrl = process.env.REACT_APP_API_URL;

const LoginPage = () => {
  const navigate = useNavigate();



  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [vatNumber, setVatnumber] = useState('');
  const [password, setPassword] = useState('');

  //setLoading(true);

  const cpfMask = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  const setMask = (value) => {
    setVatnumber(cpfMask(value));
  }



  const Login = (event) => {
    event.preventDefault();
    console.log("logando ...");

    if (password == "" || vatNumber == "") {
      setShowError(true);
      return;
    }

    var jsonBodySign = {
      vatNumber: vatNumber,
      password: password
    };

    console.log("json", jsonBodySign);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBodySign)
    };


    // chama função de assinar
    setLoading(true);

    fetch(baseUrl + 'authentication/login', requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.success) {
            localStorage.setItem("business-token", result.id);
            navigate('/signs');
          } else if (result.status === 400) {
            setShowError(true);
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log(error);
          setShowError(true);
        }
      )
  }

  return (

    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

        <img className='w-80 mb-5' src='https://hermescertificadora.blob.core.windows.net/st-k8s-hermes-images/logo-hermes-350.png' alt="logo" />
        <form className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0" onSubmit={Login}>
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Faça login em sua conta
            </h1>
            <div className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="vatNumber" className="block mb-2 text-sm font-medium text-gray-900 ">CPF</label>
                <input type="text" name="vatNumber" id="vatNumber" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="000.000.000-00" required=""
                  maxLength={14} value={vatNumber} onChange={(e) => setMask(e.target.value)} />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Senha</label>
                <input type="password" name="password" id="password" placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required=""
                  value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  {/* <div className="flex items-center h-5">
                      <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="" />
                    </div> */}
                  {/* <div className="ml-3 text-sm">
                      <label for="remember" className="text-gray-500">Manter logado?</label>
                    </div> */}
                </div>
                <a href="#" className="text-sm font-medium text-primary-600 hover:underline" onClick={() => navigate('/reset-password')}>Recuperação de senha</a>
              </div>
              <button type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Entrar
              </button>
              <p className="text-sm font-light text-gray-500">
                Não tem uma conta ainda? <a href="#" className="font-medium text-primary-600 hover:underline" onClick={() => navigate('/register')}>Criar Conta</a>
              </p>
            </div>
          </div>
          {showError && <ErrorMessage title={'Erro ao realizar a autenticação de usuário:'} msg={'Verifique as informações fornecidas e tente novamente.'} />}
        </form>
        {loading && (
          <div className="flex flex-col items-center justify-center mt-5">
            <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
    </section>
  );
}

export default LoginPage;