import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { LogoLayout } from "../../Components/LogoLayout";
import { TokenListView } from "../../Components/TokenListView";


const baseUrl = process.env.REACT_APP_API_URL;

const TokenListViewPage = () => {
    const navigate = useNavigate();

    const [signerBatchData, setSignerBatchData] = useState({
        // documents: [],
        signerId: '',
        signerBatchId: '',
        batchGroupId: '',
        clientName: '',
        batchName: '',
        signerName: '',
        allDocumentsSigned: false,
        isFinished: false,
        isFullSignature: false,
    });
    const [signerBatchDataLoaded, setSignerBatchDataLoaded] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function getSignerBatchInfo() {

            // const requestOptions = {
            //     method: 'GET',
            //     headers: { 'Content-Type': 'application/json' },
            // };

            // const response = await fetch(baseUrl + 'files-storage/signer-batch/' + hash, requestOptions);

            // if (response.status === 200) {
            //     const data = await response.json();
            //     // console.log('signer batch data', data);
            //     setSignerBatchData(data);
            //     // closeToken(hash);

            // }
            // else if (response.status === 400) {
            //     navigate('/token-validation?hash=' + hash);
            // }

            setLoading(false);
            setSignerBatchDataLoaded(true);

            // setDocumentsDataLoaded(true);    

        }

        if (!signerBatchDataLoaded) {
            setLoading(true);
            getSignerBatchInfo();
        }

    }, [navigate, signerBatchDataLoaded]);



    const openModalViewDocument = (url) => {
        navigate('/token-validation?hash=0CCF36560FF96AB12975448F75C3ABD6296DE877DDD3AED072B4587DE7983D66');
    }

    return (
        <>
            <div className="bg-white">
                <div className="mx-auto sm:text-center">
                    <nav class="bg-white border-gray-200">
                        <div class="flex flex-wrap items-center justify-between py-3 sm:py-3 lg:py-3 sm:px-3 md:px-12 lg:px-32">
                            <a href="https://flowbite.com/" class="flex items-center">
                                <img className='w-80 m-auto mb-5' src='https://hermescertificadora.blob.core.windows.net/st-k8s-hermes-images/logo-hermes-350.png' alt="logo" style={{width: "240px"}} />
                            </a>
                            <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false">
                                <span class="sr-only">Open main menu</span>
                                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                            </button>
                            <div class="hidden w-full md:block md:w-auto" id="navbar-default">
                                <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
                                    <li>
                                        <a href="#" onClick={() => navigate('/signs')} class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0" aria-current="page">Inicio</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Tokens</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Perfil</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div className="bg-white py-4 sm:py-4 lg:py-4 sm:px-3 md:px-12 lg:px-32">
                <div className="mx-auto sm:text-center">
                    {loading && (
                        <div className="flex flex-col items-center justify-center mt-5">
                            <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    )}

                    {!loading && (
                        <div className="text-center">
                            <h2 className="text-lg uppercase">João Lara</h2>
                            <h2 className="text-lg uppercase">038.842.739-69</h2>
                            <h2 className="text-lg">joao.lara@njpartners.com.br</h2>

                            <TokenListView
                                signerId={signerBatchData.signerId}
                                onClickDocument={openModalViewDocument} />
                        </div>
                    )}
                </div>
            </div>

        </>


    );
}


export default TokenListViewPage;

