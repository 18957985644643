import { useEffect } from "react";
import { useState } from "react";

// Hook
function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }); // Empty array ensures that effect is only run on mount and unmount
  return keyPressed;
}


export default function FullWidthModal({ showModal, title, content, onClose }) {

  const [showModalState, setShowModalState] = useState(showModal);
  const escPress = useKeyPress("Escape");

 
  const setModalState = (value) => {
    if (!value && !!onClose) {
        onClose();
    }

    setShowModalState(value);
        
  }

  useEffect(() => {
    if (escPress) {
      setModalState(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escPress])

  useEffect(() => {
    setShowModalState(showModal);
  }, [showModal])

 

  return (
    <>
      {/* <button
        className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Open regular modal
      </button> */}
      {showModalState ? (
        <>
          <div className="p-3 w-full h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mx-auto">
              {/*content*/}
              <div className="shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-base font-mono">
                    {title}
                  </h3>
                  <button
                    className="ml-5 p-1 ml-auto opacity-70 hover:opacity-30"
                    onClick={() => setModalState(false)}
                  >
                    <span className="font-mono text-black text-2xl font-semibold block outline-none focus:outline-none mb-2">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto">
                    {!!content && content()}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="rounded hover:bg-red-900 text-stone-50 bg-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setModalState(false)}
                  >
                    Fechar
                  </button>
                  {/* <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"  onClick={() => console.log('click out')}></div>
        </>
      ) : null}
    </>
  );
}