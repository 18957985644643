
import React, { useState, useEffect } from 'react';
import Pagination from "./Pagination";
const baseUrl = process.env.REACT_APP_API_URL;

function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
 }


export function DocumentListView({ signerId, batchGroupId, signerBatchId, isFinished,  onClickDocument }) {

    // const [currentDocuments, setCurrentDocuments] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(window.sessionStorage.getItem('pageSize') ?? 10);


    const [documentsData, setDocumentsData] = useState({
        page: 1,
        totalPages: 0,
        totalItems: 0,
        pageSize: pageSize,
        data: []
    });

    const [documentsDataLoaded, setDocumentsDataLoaded] = useState(false);  
    // const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);

    const onChangePage = (currentPage) => {
        setPage(currentPage);
        setDocumentsDataLoaded(false);
    }


    const onChangePageSize = (pageSize) => {
        if (!!pageSize && pageSize > 0) {
            setPageSize(pageSize);
            window.sessionStorage.setItem('pageSize', pageSize);
        }
        else {
            setPageSize(window.sessionStorage.getItem('pageSize') ?? 10); 
        }

        setPage(1);
        setDocumentsDataLoaded(false);
    }

    useEffect(() => {    
        async function getDocuments() {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };


            const filter = encodeQueryData({ signerId, batchGroupId, signerBatchId, page, pageSize });    
            const response = await fetch(baseUrl + 'files-storage/signer-document/query?' + filter, requestOptions);

            if (response.status === 200) {
                const data = await response.json();
                setTimeout(() => {
                    setDocumentsData(data);
                    setLoading(false);
                }, 500);
            }
            // else if (response.status === 400) {
            //     navigate('/token-validation?hash=' + hash);
            // }

            // setDocumentsDataLoaded(true);    

        }
        
        if (!documentsDataLoaded) {
            setLoading(true);
            getDocuments();
        }
      
    }, [documentsDataLoaded, page, pageSize, signerId, batchGroupId, signerBatchId]);


    const classLoading =  loading ? 'opacity-20' : '';
    return (

        <>
         
            <div className="text-center">
                <div className="relative rounded-lg border border-gray-200 shadow-md m-3 overflow-x-auto">

                    <table className={'w-full border-collapse bg-white text-left text-sm text-gray-500 z-10 ' + classLoading}>
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-start">Documento</th>
                                <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-start">Status</th> 
                                <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">Assinaturas</th>
                                <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">Assinado</th>
                                <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">Documento original</th>
                                {isFinished && <th scope="col" className="px-6 py-4 font-medium text-gray-900 text-center">Documento assinado</th>}
                            </tr>
                        </thead>
                        <tbody className={'divide-y divide-gray-100 border-t border-gray-100'}>
                            {
                                documentsData.data.map(signerDoc => (
                                    <tr key={signerDoc.id} className="hover:bg-gray-50">
                                        <th className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                                            <div className="text-sm">
                                                <div className="font-medium text-gray-700">{signerDoc.document.sourceName}</div>
                                            </div>
                                        </th>
                                        <td className="px-6 py-4">
                                            <span
                                                className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                                            >
                                                <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                                {signerDoc.document.actualStatusDescription}
                                            </span>
                                        </td>


                                        <td className="px-6 py-4 text-center">
                                            {signerDoc.document.totalSign}/{(signerDoc.document.minimumSign > 0 ? signerDoc.document.minimumSign : signerDoc.document.totalSigners)}
                                        </td>

                                        <td className="px-6 py-4"> 
                                            <div className="flex justify-center items-center gap-4">
                                                {!signerDoc.isSigned && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                )}


                                                {signerDoc.isSigned && (
                                                <span className="flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-500">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                    </svg>

                                                    <span className="ml-1">{new Date(signerDoc.signedAt).toLocaleDateString()} - {new Date(signerDoc.signedAt).toLocaleTimeString()}</span>  
                                                </span>  
                                                )}
                                            </div>
                                        </td>

                                        <td className="px-6 py-4">
                                            <div className="flex justify-center gap-4">
                                                <button className="cursor-pointer" onClick={() => onClickDocument(signerDoc.document.sourceName, signerDoc.id, false)}>
                                                    <span x-data="{ tooltip: 'Visualizar' }" href="#" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                        
                     
                                        {isFinished &&
                                            <>
                                                {signerDoc.document.isFinished ? (
                                                    <td className="px-6 py-4">
                                                    <div className="flex justify-center gap-4">
                                                        <button className="cursor-pointer" onClick={() => onClickDocument(signerDoc.document.sourceName, signerDoc.id, true)}>
                                                            <span x-data="{ tooltip: 'Visualizar' }" href="#" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                    </td>
                                                ) : ( 
                                                    <td className="px-6 py-4">
                                                    <div className="flex justify-center gap-4">
                                                        <span className="flex items-center">
                                                            <div role="status">
                                                                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                            Gerando arquivo
                                                        </span>
                                                    </div>
                                                    </td>
                                                )}
                                            </>
                                        }
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    <div className='border-t-2'></div>
                </div>
                <Pagination
                    loading={loading} 
                    totalItems={documentsData.totalItems} 
                    totalPages={documentsData.totalPages} 
                    itemsPerPage={pageSize} 
                    onChangePage={onChangePage} 
                    onChangePageSize={onChangePageSize} 
                    currentPage={page} />
            </div>
        
            
        </>


       


    );
}