import React from "react";
import { InfoMessage } from "../../Components/InfoMessage";
import { LogoLayout } from "../../Components/LogoLayout";


function SignedPage() {
    return (
        <LogoLayout>
            <InfoMessage title="Assinado com sucesso!" message="Você receberá uma cópia do documento assinado" /> 
        </LogoLayout>
    );
}

export default SignedPage;