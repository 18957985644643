const objError = (error) => ({
    errorCode: error.errorCode,
    propertyName: error.propertyName.toLowerCase(),
    errorMessage: error.errorMessage 
});

const hasValues = (errors) => errors.length > 0;

const getFirst = (errors) => {
    const firstError = errors[0];

    if (!!firstError) {
        return objError(firstError);
    }

    return undefined;
}
const find = (search, errors) => {
    const exists = errors.find(search);

    if (!!exists) {
        return objError(exists);
    }

    return undefined;
}

export function getApiErrors(result) {
    if (Array.isArray(result.errors)) {       
        return {
            hasValues: hasValues(result.errors),
            getFirst: () => getFirst(result.errors),
            find: (search) => find(search, result.errors),
            errors: []
        }
    }

    return {
        hasValues: false,
        getFirst: () => undefined,
        find: (search) => undefined,
        errors: []
    }
}