import axios from 'axios';
import { useContext } from 'react';
import { AuthContext } from "../context/useAuth";
const baseApiUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: baseApiUrl,

});


export var apiPrivate = () => {
  var token = localStorage.getItem("business-token");
  return axios.create({

    baseURL: baseApiUrl,
    headers: {
      'Authorization': token ? `Bearer ${token}` : null
    },
  })
}
