import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { LogoLayout } from "../../Components/LogoLayout";
import { SignListView } from "../../Components/SignListView";
import jwt_decode from "jwt-decode";
import { AuthProvider } from "../../context/useAuth";
import { apiPrivate } from "../../api/api";
import Header from '../../Components/Header';

const baseUrl = process.env.REACT_APP_API_URL;

const SignListViewPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [vatNumber, setVatnumber] = useState('');
    const [signerId, setSignerId] = useState('');
    const [userId, setUserId] = useState('');


    const cpfMask = value => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }
    const setMask = (value) => {
        setVatnumber(cpfMask(value));
    }

    const [signerBatchData, setSignerBatchData] = useState({
        // documents: [],
        signerId: '',
        signerBatchId: '',
        batchGroupId: '',
        clientName: '',
        batchName: '',
        signerName: '',
        allDocumentsSigned: false,
        isFinished: false,
        isFullSignature: false,
    });
    const [signerBatchDataLoaded, setSignerBatchDataLoaded] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function getSignerBatchInfo() {
         
            const response = await apiPrivate().get(baseUrl + 'authentication/authenticated/me', {validateStatus: () => true});

            if (response.status === 200) {
                const { data } = await response;
                setSignerBatchData(data);
                var token = localStorage.getItem('business-token');
                var decoded = jwt_decode(token);

                setEmail(decoded.email);
                setMask(decoded.VatNumber);
                setSignerId(decoded.SignerId);
                setUserId(decoded.UserId)
            }
            else if (response.status === 400 || response.status === 401 || response.status === 403) {
                navigate('/login');
            }
            else if (response.status === 500) {
                navigate('/login');
            }
           
            setLoading(false);
            setSignerBatchDataLoaded(true);
        }

        if (!signerBatchDataLoaded) {
            setLoading(true);
            getSignerBatchInfo();
        }

    }, [navigate, signerBatchDataLoaded]);



    const openModalViewDocument = (url) => {
        window.open(url, "_blank");
    }



    return (
        <AuthProvider>
            <LogoLayout hasExit={true}>
                {loading && (
                    <div className="flex flex-col items-center justify-center mt-5">
                        <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                )}

                {!loading && (
                    <div className="text-center">
                        <h2 className="text-lg uppercase">{vatNumber}</h2>
                        <h2 className="text-lg">{email}</h2>

                        <SignListView
                            signerId={signerId}
                            userId={userId}
                            onClickDocument={openModalViewDocument} />
                    </div>
                )}
            </LogoLayout>
        </AuthProvider>
    );
}


export default SignListViewPage;

