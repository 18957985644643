
const baseUrl = process.env.REACT_APP_API_URL;

export const verifyToken = (obj, signature = false) => {
    if (signature && !obj.cpf)
        throw new Error('deve ter cpf para verificar token de assinatura');

    var jsonBody = {
        cpf: obj.cpf,
        token: obj.token,
        type: signature ? 2 : 1
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jsonBody)
    };

    return fetch(baseUrl + 'certification/token/verify', requestOptions)
        .then(res => res.json());
}

export const getToken = (hash, signature = false) => {
    var jsonBody = {
        hash,
        type: signature ? 2 : 1
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jsonBody)
    };

    return fetch(baseUrl + 'certification/token', requestOptions)
        .then(res => res.json());

}