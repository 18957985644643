import { LogoLayout } from "../../Components/LogoLayout";

function NotFoundPage() {
  return (
    <LogoLayout>
      <div className="flex flex-col items-center justify-center">
        <div className="mt-6 bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
          <p className="font-bold">404</p>
          <p>Página não encontrada.</p>
        </div>
      </div>
    </LogoLayout>
  );
}

export default NotFoundPage;