import { twMerge } from "tailwind-merge";

export default function Button({
  title,
  onClick,
  type = "button",
  className,
  children,
  ...props
}) {
  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge(
        "text-white bg-primary-600 hover:bg-primary-700 duration-300 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-md text-sm px-4 py-2 text-center",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}
