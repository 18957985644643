import PDFObject from 'pdfobject';
import { useEffect } from "react";

const baseUrl = process.env.REACT_APP_API_URL;

function DocumentView({ documentId, signed }) {


    const support = (navigator.pdfViewerEnabled) ? true : false;
    const url = baseUrl + 'files-storage/download/' + documentId + '?signedDocument=' + signed;

    useEffect(() => {
       if (!!support) {
            PDFObject.embed(url, '#pdfView', { height: '72vh', width: '46vw' });
       } 
    }, [support, url]);

    return (
        <div className="bg-white py-12 sm:py-24 lg:py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                {support && <div id="pdfView"></div>}
                {!support && 
                <div>
                    <a target="_blank" rel="noreferrer" href={url + '&download=true'} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        Browser não suporta visualização de documentos, clique para realizar o download
                    </a>
                </div>}
            </div>
        </div>
    );
}

export default DocumentView;