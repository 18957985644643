import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import ErrorMessage from "../../Components/ErrorMessage";
import SuccessMessageComponent from '../../Components/SuccessMessage';


const baseUrl = process.env.REACT_APP_API_URL;

const RegisterPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setShowSuccessMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [vatNumber, setVatnumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [termIsChecked, setTermIsChecked] = useState(false);

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const setMask = (value) => {
    setVatnumber(cpfMask(value));
  }

  useEffect(() => {
    setTimeout(() => {

      // load da tela

      setLoading(false);


    }, 2000);


  });

  async function Register() {
    console.log("logando ...");

    if(!termIsChecked){
      setShowError(true);
      setErrorMessage("É obrigatório aceitar os termos de uso do sistema para realizar o cadastro");
      return;

    }

    if (password != confirmPassword) {
      setShowError(true);
      setErrorMessage("Erro ao cadastrar usuário, o campo senha e confirmar senha devem ser iguais");
      return;

    }

    if (email == "" || vatNumber == "" || password == "") {
      setShowError(true);
      setErrorMessage("Erro ao cadastrar usuário, verifique seus dados e tente novamente");
      return;
    }

    if (password.length < 6 ||
      password.length > 12) {
      setShowError(true);
      setErrorMessage("A senha precisar ter o mínimo de 6 e máximo de 12 caracteres");
      return;
    }

    var jsonBodySign = {
      email: email,
      password: password,
      vatNumber: vatNumber.replace('.', '').replace('.', '').replace('-', ''),
      acceptTerms: termIsChecked
    };

    console.log("json", jsonBodySign);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(jsonBodySign)
    };
    

    // chama função de assinar
    setLoading(true);
    var result = await fetch(baseUrl + 'users/user', requestOptions);

    if (result.status === 200) {
      setShowSuccess(true);
      setShowSuccessMessage("Você será redirecionado para tela de autenticação!");
      setTimeout(() => {
        navigate('/login');
      }, 3000);

    } else if (result.status === 400) {
      const data = await result.json();

      if (data.detail != null) {
        setErrorMessage(data.detail);
      }
      console.log(result);
      console.log(data);
      setShowError(true);
      setShowSuccess(false);
    }
    setLoading(false);


  }


  return (

    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

        <img className='w-80 mb-5' src='https://hermescertificadora.blob.core.windows.net/st-k8s-hermes-images/logo-hermes-350.png' alt="logo" />
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Crie sua conta
            </h1>
            <div className="space-y-4 md:space-y-6">
              {showError && <ErrorMessage title={'Erro ao realizar cadastro:'} msg={errorMessage} />}
              {showSuccess && <SuccessMessageComponent title={'Cadastro realizado:'} msg={successMessage} />}
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="seunome@suaempresa.com" required=""
                  value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div>
                <label htmlFor="cpf" className="block mb-2 text-sm font-medium text-gray-900 ">CPF</label>
                <input type="text" name="cpf" id="cpf" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="000.000.000-00" required=""
                  value={vatNumber} onChange={(e) => setMask(e.target.value)} />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Senha</label>
                <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""
                  value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Confirmar Senha</label>
                <input type="password" name="confirmPassword" id="confirmPassword" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""
                  value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required=""
                    checked={termIsChecked} onChange={() => setTermIsChecked((prev) => !prev)} />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="terms" className="font-light text-gray-500">Eu aceito os <a className="font-medium text-primary-600 hover:underline" href="#">Termos e condições</a></label>
                </div>
              </div>
              <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={() => Register()} >Criar conta</button>
              <p className="text-sm font-light text-gray-500">
                já tem uma conta? <a href="#" className="font-medium text-primary-600 hover:underline" onClick={() => navigate('/login')}> Entre aqui</a>
              </p>
            </div>
          </div>

        </div>
        {loading && (
          <div className="flex flex-col items-center justify-center mt-5">
            <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
    </section>
  );
}

export default RegisterPage;