import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import ErrorMessage from "../../Components/ErrorMessage";
import SuccessMessageComponent from '../../Components/SuccessMessage';
import ReCAPTCHA from "react-google-recaptcha";

const baseUrl = process.env.REACT_APP_API_URL;

const NewPasswordPage = () => {
  const [captcha, setCaptcha] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [userDataLoaded, setUserDataLoaded] = useState(false);

  const [email, setEmail] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setShowSuccessMessage] = useState(false);

  const hash = searchParams.get('hash');
  const emailParam = searchParams.get('ue');
  const vatNumberParam = searchParams.get('vn');

  // data load
  useEffect(() => {

    if (!hash) {
      navigate('/login');
      return;
    }

    async function getUserInfo() {
      var decodedEmail = atob(emailParam);
      console.log(decodedEmail);

      var decodedVatNumber = atob(vatNumberParam);
      console.log(decodedVatNumber);

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      const response = await fetch(`${baseUrl}users/user/validate-reset-password/${hash}?email=${decodedEmail}&vatNumber=${decodedVatNumber}`, requestOptions);

      if (response.status === 200) {
        const data = await response.json();
        console.log(data.email);
        console.log(data.userId);

        setEmail(data.email);
        setUserId(data.userId);
      }
      else if (response.status === 400) {
        setShowError(true);
      }

      setLoading(false);
      setUserDataLoaded(true);

      if (!setUserDataLoaded) {
        setLoading(true);
      }

    };

    setTimeout(() => {
      setLoading(false);
      getUserInfo();
    }, 500);
  }, [hash, navigate, userDataLoaded]);

  async function newPasswordGenerate() {
    if (password != confirmPassword) {
      setShowError(true);
      setErrorMessage("Erro ao cadastrar usuário, o campo senha e confirmar senha devem ser iguais");
      return;

    }

    if (email == "" || password == "") {
      setShowError(true);
      setErrorMessage("verifique seus dados e tente novamente");
      return;
    }

    if (password.length < 6 ||
      password.length > 12) {
      setShowError(true);
      setErrorMessage("A senha precisar ter o mínimo de 6 e máximo de 12 caracteres");
      return;
    }

    var jsonBodySign = {
      email: email,
      userId: userId,
      password: password
    };

    console.log("json", jsonBodySign);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBodySign)
    };
    const response = await fetch(baseUrl + 'users/user/confirm-reset-password/' + hash, requestOptions);

    if (response.status === 200) {
      setShowSuccess(true);
      setShowSuccessMessage("Agora você pode acessar nossa plataforma utilizando a nova senha que você definiu");

      setTimeout(() => {
        navigate('/login');
      }, 10000);

    }
    else if (response.status === 400) {
      setShowError(true);
    }

    setLoading(false);
    setUserDataLoaded(true);

    if (!setUserDataLoaded) {
      setLoading(true);
    }
  };

  return (

    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <img className='w-80 mb-5' src='https://hermescertificadora.blob.core.windows.net/st-k8s-hermes-images/logo-hermes-350.png' alt="logo" />
        {showError && <ErrorMessage title={'Erro ao alterar a senha:'} msg={errorMessage ?? 'Verifique os dados informados'} />}
        {showSuccess && <SuccessMessageComponent title={'A senha da sua conta foi alterada com sucesso.'} msg={successMessage} />}
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Criar nova senha
            </h1>
            <div className="space-y-4 md:space-y-6">
              <div>
                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                <input type="email" name="email" id="email" disabled="disabled" value={email} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="seunome@suaempresa.com" required=""
                />
              </div>
              <div>
                <label for="password" className="block mb-2 text-sm font-medium text-gray-900">Nova Senha</label>
                <input type="password" name="password" id="password" placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""
                  onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div>
                <label for="confirm-password" className="block mb-2 text-sm font-medium text-gray-900">Confirmar Nova Senha</label>
                <input type="password" name="confirm-password" id="confirm-password" placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""
                  onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={setCaptcha} />
              {captcha &&
                <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={() => newPasswordGenerate()} >
                  Alterar Senha
                </button>
              }

            </div>
          </div>

        </div>

        {loading && (
          <div className="flex flex-col items-center justify-center mt-5">
            <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
    </section>
  );
}

export default NewPasswordPage;