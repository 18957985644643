import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'


const Pagination = ({ loading, totalItems = 0,  totalPages = 0, itemsPerPage = 10, currentPage = 1, onChangePage, onChangePageSize }) => {

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const space = 2;
    let start = currentPage > (space + 1) ? currentPage - space : 1;
    let end = currentPage > (space + 1) ? currentPage + space : 5;
    if (start < 1)
      start = 1;

    if (end > totalPages) {
      if (totalPages >= 5) {
        start = start - (end - totalPages);
      }
      end = totalPages;

    }



    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <li key={i} className={`w-10 h-10 font-semibold  duration-200 border-solid border-2 border-r-0 border-blue-500 flex items-center justify-center hover:!bg-blue-500 hover:text-white bg-blue-500  ${currentPage === i ? "!text-white !bg-blue-500": "!bg-white !text-blue-500"}}`}>
          <button disabled={loading} className="w-full h-full flex items-center justify-center" onClick={() => onChangePage(i)}>{i}</button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex flex-wrap items-center justify-between mb-3 m-5">
      <div className="flex items-center">
        <select disabled={loading} onChange={(e) => onChangePageSize(e.target.value)} value={itemsPerPage} className="border-solid border-2 border-blue-500 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 p-2.5">
            <option>Items por página</option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
        </select>
        <span className="mx-2">Total documentos: {totalItems} - {currentPage}/{totalPages} páginas</span>

        {loading && (
            <svg className="animate-spin h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>       
        )}
      </div>




      <ul className="flex items-center justify-end">
        {totalPages > 0 && (
          <>
            <li className="w-10 h-10 hover:bg-blue-500 hover:text-white duration-200 rounded-tl-md rounded-bl-md bg-white border-r-0 border-solid border-2 border-blue-500 ">
              <button disabled={loading} className="w-full h-full flex items-center justify-center" onClick={() => onChangePage(1)}>
                <ChevronLeftIcon width={15} />
              </button>
            </li>
            {renderPageNumbers()}
            <li className="w-10 h-10 hover:bg-blue-500 hover:text-white duration-200 rounded-tr-md rounded-br-md bg-white border-solid border-2 border-blue-500 flex items-center justify-center">
              <button disabled={loading} className="w-full h-full flex items-center justify-center" onClick={() => onChangePage(totalPages)}>
                <ChevronRightIcon width={15} />
              </button>
            </li>
          </>
        )}
      </ul>

    
    </div>
  );
}

export default Pagination;