
import React, { useState, useEffect, useRef } from 'react';
import Pagination from "./Pagination";
import { useNavigate } from "react-router-dom";
import { debounce } from 'lodash';
import { addDays, format, isDate } from "date-fns";
import localePtBr from 'date-fns/locale/pt-BR';
import { CalendarDaysIcon, ChevronDownIcon, ChevronUpIcon, FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Select from 'react-select';
import { DateRange } from 'react-date-range';
import styles from './SignListView.module.css'
import { apiPrivate } from '../api/api'
import { useAuth } from "../context/useAuth";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

function filterInvalidProperties(params) {
    let list = params;
    Object.keys(list).forEach((key) => {
        if (params[key] === undefined || params[key] === null || params[key] === '') {
            delete list[key];
        }
    });
    return list;
}

const selectCustomStyles = {
    control: (styles) => ({
        ...styles,
        borderRadius: '0.375rem',
        height: '2.625rem',
        border: 'solid 2px rgb(229 231 235 / 1);',
        transition: 'all 0.3s ease',
        ':hover': {
            ...styles[':active'],
            border: 'solid 2px #818cf8;',
        },
        ':focus': {
            ...styles[':focus'],
            border: 'solid 2px #818cf8;',
        }
    }),
};



function parseDateFormat(date, dateFormat) {
    if (!isDate(date)) return null;
    let dateFormatted = format(date, dateFormat);
    return dateFormatted;
}

export function SignListView({ signerId, userId, onClickDocument }) {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const api = apiPrivate;

    // const [currentDocuments, setCurrentDocuments] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(window.sessionStorage.getItem('pageSize') ?? 10);
    const [clientes, setClientes] = useState();
    const containerFilterRef = useRef(null);
    const containerDateRangeRef = useRef(null);
    const [documentsDataLoaded, setDocumentsDataLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const classLoading = loading ? 'opacity-20' : '';
    const [enableDateRange, setEnableDateRange] = useState(false);
    const [enableFilter, setEnableFilter] = useState(false);
    const [badgeFilter, setBadgeFilter] = useState([]);
    const [sortFilter, setSortFilter] = useState(false);
    const [buttonCountFilter, setButtonCountFilter] = useState(0);
    const lastWeek = -7;
    const [showModal, setShowModal] = useState(false);
    const [canSendWebhook, setCanSendWebhook] = useState(false);


    const [btnActiveFilter, setBtnActiveFilter] = useState({
        btnFilter: false,
        btnSelectDate: false
    });

    const [dateRange, setDateRange] = useState({
        startDate: addDays(new Date(), lastWeek),
        endDate: new Date(),
        key: 'selection',
    });

    const [paginationFilter, setPaginationFilter] = useState({
        assinado: { value: '', label: 'Todos' },
        cliente: { value: '', label: 'Todos' },
        finalizado: { value: '', label: 'Todos' },
        envelope: '',
    })

    const [dateRangeFilter, setDateRangeFilter] = useState({
        dataInicio: null,
        dataFim: null,
    })

    const [documentsData, setDocumentsData] = useState({
        page: 1,
        totalPages: 0,
        totalItems: 0,
        pageSize: pageSize,
        data: []
    });

    const [assignOptions, setAssignOptions] = useState([
        { value: null, label: 'Todos' },
        { value: true, label: 'Documentos assinados' },
        { value: false, label: 'Documentos não assinados' }
    ]);
    const [batchGroupFinishedOptions, setBatchGroupFinishedOptions] = useState([
        { value: null, label: 'Todos' },
        { value: true, label: 'Documentos finalizados' },
        { value: false, label: 'Documentos não finalizados' }
    ]);



    const handleClickOutside = (event) => {
        if (containerDateRangeRef.current && !containerDateRangeRef.current.contains(event.target)) {
            setEnableDateRange(false);
        }

        if (containerFilterRef.current && !containerFilterRef.current.contains(event.target)) {
            setEnableFilter(false);
        }
    };


    function handleSetDefaultValueFilter() {
        setPaginationFilter({
            assinado: false,
            cliente: '',
            finalizado: false,
            envelope: '',
        })
        setDateRangeFilter({
            dataInicio: null,
            dataFim: null,
        })

        setDateRange({
            startDate: addDays(new Date(), lastWeek),
            endDate: new Date(),
            key: 'selection',
        })
        setButtonCountFilter(0);
    }

    function handleChangeBatchGroupName(text) {
        setPaginationFilter({ ...paginationFilter, envelope: text });
    }

    const debounceBatchGroupName = debounce(handleChangeBatchGroupName, 400);



    const handleDateChange = ({ selection }) => {
        let { startDate, endDate } = selection;
        setDateRangeFilter({ ...dateRangeFilter, dataInicio: startDate, dataFim: endDate })
        setDateRange(selection);
        activeBtnSelectDate(dateRangeFilter)
    };





    const onChangePage = (currentPage) => {
        setPage(currentPage);
        setDocumentsDataLoaded(false);
    }

    const onChangePageSize = (pageSize) => {
        if (!!pageSize && pageSize > 0) {
            setPageSize(pageSize);
            window.sessionStorage.setItem('pageSize', pageSize);
        }
        else {
            setPageSize(window.sessionStorage.getItem('pageSize') ?? 10);
        }

        setPage(1);
        setDocumentsDataLoaded(false);
    }



    function activeBtnFilter(filter) {
        let filterValidProperties = filterInvalidProperties({
            cliente: filter.cliente?.value,
            assinado: filter.assinado?.value,
            finalizado: filter.finalizado?.value,
            envelope: filter.envelope,
        });

        let countFiltered = Object.keys(filterValidProperties).reduce((count, key) => {
            if (filterValidProperties[key]) {
                count++;
            }
            return count;
        }, 0);
        setButtonCountFilter(countFiltered)
        setBtnActiveFilter({
            ...btnActiveFilter,
            btnFilter: countFiltered > 0 ? true : false
        })
    }

    function activeBtnSelectDate(filter) {
        setBtnActiveFilter((prevState) => {
            if (isDate(filter.dataInicio) || isDate(filter.dataFim)) {
                prevState.btnSelectDate = true
            } else {
                prevState.btnSelectDate = false
            }
            return prevState
        })
    }



    async function getDocuments() {
        const filteredParams = filterInvalidProperties({
            signerId,
            page,
            pageSize,
            clientId: paginationFilter.cliente?.value,
            fullSigned: paginationFilter.assinado?.value,
            isBatchGroupFinished: paginationFilter.finalizado?.value,
            batchGroupName: paginationFilter.envelope,
            sort: sortFilter ? sortFilter : null,
            since: parseDateFormat(dateRangeFilter.dataInicio, 'yyyy-MM-dd'),
            until: parseDateFormat(dateRangeFilter.dataFim, 'yyyy-MM-dd')
        });


        const response = await api().get('/files-storage/signer-batch/query', { params: filteredParams });

        if (response.status === 200) {
            const data = response.data;

            setTimeout(() => {
                setDocumentsData(data);
                setLoading(false);
                // console.log("data", data);
                // console.log("documentsData", documentsData);
            }, 500);
        }
        else if (response.status === 400 || response.status === 401 || response.status === 403) {
            // navigate('/login');
        }

        // setDocumentsDataLoaded(true);    

    }

    async function getClients() {
        const response = await api().get(`/users/client/${userId}`);
        if (response.status === 200) {
            let result = response.data.map((item) => {
                return { value: item.id, label: item.name }
            })
            result.unshift({ value: '', label: 'Todos' })

            setClientes(result)
        }
    }

    const openSignatureModal = (id) => {
        console.log(id);
        navigate('/signatures/?id=' + id);
    }

    async function sendWebhook(id) {

        var param = { batchGroupId: id };
        setLoading(true);
        const response = await api().post('/batch/webhook/resend/', param, { validateStatus: () => true });

        if (response.status === 200) {
            toast("webhook enviado com sucesso!", {
                type: 'success',
                autoClose: 2000,
                theme: 'colored'
            });
        } else {
            console.log(response);
            toast("erro ao enviar webhook, contate o administrador do sistema", {
                type: 'error',
                autoClose: 2000,
                theme: 'colored'
            });
        }
        setLoading(false);
    }


    const handleRemoveBadgeFilterItem = (item) => {
        const badgeFilteredList = badgeFilter.filter((i) => i !== item);
        setBadgeFilter(badgeFilteredList);
    }

    function createBadgeFilter(filter) {
        let filters = filter;
        let filterBadge = []
        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                filterBadge.push({ name: key })
            }
        });
        setBadgeFilter(filterBadge)
    }

    useEffect(() => {
        getClients();
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isAuthenticated()) {
            setLoading(true);
            getDocuments();

        }
    }, [documentsDataLoaded, page, pageSize, signerId, paginationFilter, dateRangeFilter, sortFilter]);

    useEffect(() => {
        activeBtnFilter(paginationFilter)
    }, [paginationFilter])

    useEffect(() => {
        activeBtnSelectDate(dateRangeFilter)
    }, [dateRangeFilter])

    useEffect(() => {

        var token = localStorage.getItem('business-token');
        var decoded = jwt_decode(token);

        setCanSendWebhook(decoded.role === "admin" || decoded.role === "manager");
    });



    return (
        <>

            <div >
                <div className="flex m-3 space-x-3 items-end justify-end ">
                    {(btnActiveFilter.btnFilter || btnActiveFilter.btnSelectDate) && (
                        <div>
                            <button
                                onClick={() => handleSetDefaultValueFilter()}
                                className="flex items-center border-2 border-solid border-red-500 bg-red-500 text-white p-2 rounded-md text-sm font-medium">
                                Limpar
                                <XMarkIcon className="w-4 h-4 font-medium" />
                            </button>
                        </div>
                    )}

                    <div ref={containerFilterRef} className="relative">
                        <button
                            onClick={() => setEnableFilter(!enableFilter)}
                            className={`${btnActiveFilter.btnFilter && "border-indigo-400 text-indigo-400"} relative flex items-center justify-center px-3 py-2 text-sm font-medium rounded-md border-2 ease duration-300 border-solid border-gray-200 hover:text-indigo-400 hover:border-indigo-400`}
                        >
                            Filtros
                            {buttonCountFilter > 0 && (
                                <div className="w-5 h-5 ml-2 -mr-1 leading-relaxed bg-indigo-400 rounded-full text-xs flex align-center justify-center text-white">
                                    <span>{buttonCountFilter}</span>
                                </div>
                            )}

                            {buttonCountFilter <= 0 && (
                                <FunnelIcon className="w-4 h-4 ml-3" />
                            )}


                        </button>

                        {enableFilter && (
                            <div className="absolute block p-5 right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg z-10 -mr-[11.25rem] w-[22rem]  sm:mr-0 text-left">
                                <h1 className="text-lg font-medium mb-4">Adicionar filtro</h1>
                                <ul className="w-full flex flex-col gap-4">
                                    <li>
                                        <label className="text-sm font-medium inline-block mb-1">Envelope</label>
                                        <input
                                            defaultValue={paginationFilter.envelope}
                                            type="text"
                                            onChange={e => { debounceBatchGroupName(e.target.value) }}
                                            placeholder="Nome do envelope"
                                            className="py-2 w-full block rounded-md border-gray-200 shadow-sm border-2 border-solid ease duration-300  hover:border-indigo-400 focus:border-indigo-400 focus:outline-0 focus:ring-0" />
                                    </li>
                                    <li>
                                        <label className="text-sm font-medium inline-block mb-1">Cliente</label>
                                        <Select
                                            disabled={loading}
                                            onChange={(e) => setPaginationFilter({ ...paginationFilter, cliente: e })}
                                            defaultValue={paginationFilter.cliente}
                                            placeholder="Selecionar cliente"
                                            options={clientes}
                                            className={`${styles.reactSelectInputFix} w-full text-left hover:border-indigo-400 focus:border-indigo-400`}
                                            styles={selectCustomStyles}
                                        >
                                        </Select>
                                    </li>
                                    <li className="w-full">
                                        <label className="text-sm font-medium inline-block mb-1">Assinado</label>
                                        <Select
                                            disabled={loading}
                                            onChange={(e) => setPaginationFilter({ ...paginationFilter, assinado: e })}
                                            defaultValue={paginationFilter.assinado}
                                            placeholder="Selecionar opção"
                                            options={assignOptions}
                                            className={`${styles.reactSelectInputFix} w-full text-left hover:border-indigo-400 focus:border-indigo-400`}
                                            styles={selectCustomStyles}
                                        >
                                        </Select>
                                    </li>
                                    <li className="w-full">
                                        <label className="text-sm font-medium inline-block mb-1">Finalizado</label>
                                        <Select
                                            disabled={loading}
                                            onChange={(e) => setPaginationFilter({ ...paginationFilter, finalizado: e })}
                                            defaultValue={paginationFilter.finalizado}
                                            placeholder="Selecionar opção"
                                            options={batchGroupFinishedOptions}
                                            className={`${styles.reactSelectInputFix} w-full text-left hover:border-indigo-400 focus:border-indigo-400`}
                                            styles={selectCustomStyles}
                                        >
                                        </Select>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div ref={containerDateRangeRef} className="relative">
                        <button
                            onClick={() => setEnableDateRange(!enableDateRange)}
                            className={`${btnActiveFilter.btnSelectDate && "border-indigo-400 text-indigo-400"} min-w-[10.625rem] relative  flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md border-2 ease duration-300 border-solid border-gray-200 hover:text-indigo-400 hover:border-indigo-400`}
                        >
                            Selecionar Datas
                            <CalendarDaysIcon className="w-4 h-4 ml-3" />

                        </button>

                        {enableDateRange && (
                            <div className="absolute  right-0 mt-2 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                                <div className="flex flex-col">
                                    <DateRange
                                        ranges={[dateRange]}
                                        onChange={handleDateChange}
                                        locale={localePtBr}
                                        moveRangeOnFirstSelection={false}
                                        startDatePlaceholder="Início"
                                        endDatePlaceholder="Fim"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="relative rounded-lg border border-gray-200 shadow-md m-3 overflow-x-auto text-center">
                    <table className={'w-full border-collapse bg-white text-left text-sm text-gray-500 z-10 ' + classLoading}>
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Envelope</th>
                                <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Cliente</th>
                                <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">
                                    <button className="flex w-full justify-between" onClick={() => setSortFilter(!sortFilter)}>
                                        Data de Geração
                                        {sortFilter && <ChevronUpIcon className="w-4 h-4" />}
                                        {!sortFilter && <ChevronDownIcon className="w-4 h-4" />}
                                    </button>
                                </th>
                                <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Status</th>
                                <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-center">Assinado</th>
                                <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-center">Visualizar</th>
                                {
                                    canSendWebhook &&
                                    <th scope="col" className="px-2 py-4 font-medium text-gray-900 text-center">Enviar Webhook</th>
                                }

                            </tr>
                        </thead>
                        <tbody className={'divide-y divide-gray-100 border-t border-gray-100'}>
                            {
                                documentsData.data.map(doc => (
                                    <tr key={doc.batchGroupId} className="hover:bg-gray-50">
                                        <td className="flex gap-3 px-2 py-4 font-normal text-gray-900">
                                            <div className="text-sm">
                                                <div className="font-medium text-gray-700">{doc.batchName}</div>
                                            </div>
                                        </td>
                                        <td className="px-2 py-4">
                                            <div className="text-sm">
                                                <div className="font-medium text-gray-700">{doc.clientName}</div>
                                            </div>
                                        </td>
                                        <td className="px-2 py-4">
                                            <div className="text-sm">
                                                <span className="ml-1">{new Date(doc.createdAt).toLocaleDateString()} - {new Date(doc.createdAt).toLocaleTimeString()}</span>
                                            </div>
                                        </td>
                                        <td className="px-2 py-4">
                                            {doc.isFinished &&
                                                <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 cursor-pointer"
                                                    onClick={() => openSignatureModal(doc.batchGroupId)}
                                                >
                                                    <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>Finalizado

                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="0.8" stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
                                                    </svg>

                                                </span>
                                            }
                                            {!doc.isFinished &&
                                                <>
                                                    <span className="inline-flex items-center gap-1 rounded-full bg-yellow-50 px-2 py-1 text-xs font-semibold text-yellow-600 cursor-pointer"
                                                        onClick={() => openSignatureModal(doc.batchGroupId)}
                                                    >
                                                        <span className="h-1.5 w-1.5 rounded-full bg-yellow-600"></span>
                                                        Pendente
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="0.8" stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
                                                        </svg>
                                                    </span>
                                                </>
                                            }

                                        </td>

                                        <td className="px-2 py-4">
                                            {!doc.allDocumentSigned &&

                                                <div className="flex justify-center items-center gap-4">

                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </div>


                                            }
                                            {doc.allDocumentSigned &&
                                                <span className="flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-500">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                    </svg>

                                                    <span className="ml-1">{new Date(doc.signedAt).toLocaleDateString()} - {new Date(doc.signedAt).toLocaleTimeString()}</span>
                                                </span>
                                            }

                                        </td>

                                        <td className="px-2 py-4">
                                            <div className="flex justify-center gap-4">
                                                {doc.signatureUrl &&
                                                    <button className="cursor-pointer" onClick={() => onClickDocument(doc.signatureUrl)}>
                                                        <span x-data="{ tooltip: 'Visualizar' }" href="#" >
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                        {
                                            canSendWebhook &&
                                            <td className="px-2 py-4 text-center">
                                                {doc.canSendWebhook &&
                                                    <a title='sincronizar operação' className='cursor-pointer' onClick={() => sendWebhook(doc.batchGroupId)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 m-auto  hover:text-green-500 cursor-pointer">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                        </svg>
                                                    </a>
                                                }
                                            </td>
                                        }


                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='border-t-2'></div>
                </div>
                <Pagination
                    loading={loading}
                    totalItems={documentsData.totalItems}
                    totalPages={documentsData.totalPages}
                    itemsPerPage={pageSize}
                    onChangePage={onChangePage}
                    onChangePageSize={onChangePageSize}
                    currentPage={page} />
            </div >
        </>
    );
}