import Header from "./Header";
import {  useNavigate } from "react-router-dom";


export const LogoLayout = ({ children, hasExit }) => {
    const navigate = useNavigate();
    function onclickExit() {
        localStorage.setItem('business-token', '');
        navigate('/login');
        
    }

    return (<div className="bg-white py-8 sm:py-12 lg:py-8 sm:px-3 md:px-12 lg:px-32">
        <div className="mx-auto sm:text-center">
            {
                hasExit &&
                <div className="flex flex-1 justify-end">
                    <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded"
                    onClick={()=> onclickExit()}
                    >
                        Sair <span aria-hidden="true">→</span>
                    </button>
                </div>
            }
            <Header />
            {children}
        </div>

    </div>);
}






