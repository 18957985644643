import React, { useState } from 'react';
import ErrorMessage from "../../Components/ErrorMessage";
import SuccessMessageComponent from '../../Components/SuccessMessage';
import ReCAPTCHA from "react-google-recaptcha";

const baseUrl = process.env.REACT_APP_API_URL;

const ResetPasswordPage = () => {

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [email, setEmail] = useState(false);
  const [vatNumber, setVatNumber] = useState("");
  const [captcha, setCaptcha] = useState("");

  const cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  const setMask = (value) => {
    setVatNumber(cpfMask(value));
  }

  async function resetPassword() {
    setLoading(true);

    var jsonBodySign = {
      email: email,
      vatNumber: vatNumber
    };

    console.log("json", jsonBodySign);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBodySign)
    };



    setLoading(true);
    var result = await fetch(baseUrl + 'users/user/reset-password', requestOptions);
    console.log("enviou pedido de nova senha!");
    if (result.status === 200) {
      setShowError(false);
      setShowSuccess(true);
    } else if (result.status === 400) {
      const data = await result.json();
      console.log(result);
      console.log(data);
      setShowError(true);
      setShowSuccess(false);
    }
    setLoading(false);


  }

  return (

    <section className="bg-gray-50">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

        <img className='w-80 mb-5' src='https://hermescertificadora.blob.core.windows.net/st-k8s-hermes-images/logo-hermes-350.png' alt="logo" />
        {showError && <ErrorMessage title={'Erro ao solicitar a nova senha'} msg={'Verifique o email informado.'} />}
        {showSuccess && <SuccessMessageComponent title={'Recebemos sua solicitação para criar uma nova senha em nossa plataforma.'} msg={' As instruções necessárias para redefinir sua senha foram enviadas para o endereço de e-mail associado à sua conta.'} />}
        <div className="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
              Solicitar nova senha
            </h1>
            <div className="space-y-4 md:space-y-6">
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="seunome@suaempresa.com" required=""
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="cpf" className="block mb-2 text-sm font-medium text-gray-900 ">CPF</label>
                <input type="text" name="cpf" id="cpf" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="000.000.000-00" required=""
                  value={vatNumber} onChange={(e) => setMask(e.target.value)} />
              </div>

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={setCaptcha} />
              {captcha &&

                <button type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  onClick={() => resetPassword()}>Enviar</button>
              }

            </div>
          </div>

        </div>

        {loading && (
          <div className="flex flex-col items-center justify-center mt-5">
            <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        )}
      </div>
    </section>
  );
}

export default ResetPasswordPage;