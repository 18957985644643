import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { LogoLayout } from "../../Components/LogoLayout";
import { AuthProvider } from "../../context/useAuth";
import { apiPrivate } from "../../api/api";
import { toast } from "react-toastify";
import { Tabs, Tab } from '../../Components/TabComponent';
import jwt_decode from "jwt-decode";

const baseUrl = process.env.REACT_APP_API_URL;

const SignaturesListViewPage = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [signatures, setSignatures] = useState();
    const [emailsLog, setEmailsLog] = useState();
    const [dataLoaded, setdataLoaded] = useState(false);
    const [dataEmailLogLoaded, setDataEmailLogLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const classLoading = loading ? 'opacity-20' : '';
    const [canSendEmail, setCanSendEmail] = useState(false);

    const [canFinishBatchGroup, setCanFinishBatchGroup] = useState(false);
    const [canCancelBatchGroup, setCanCancelBatchGroup] = useState(false);

    useEffect(() => {

        var token = localStorage.getItem('business-token');
        var decoded = jwt_decode(token);
        console.log("token", decoded);

        console.log("decoded", decoded);

        setCanSendEmail(decoded.role === "admin" || decoded.role === "manager");

        if (canSendEmail)
            getEmailsLog();



    });


    useEffect(() => {

        async function getSignatures() {

            const response = await apiPrivate().get(`${baseUrl}files-storage/signer-batch/by-batchgroup/${id}`, { validateStatus: () => true });

            if (response.status === 200) {
                const { data } = response;
                setSignatures(data);

                setCanCancelBatchGroup(data.canCancelBatchGroup);
                setCanFinishBatchGroup(data.canFinishBatchGroup);

            }
            else if (response.status === 400 || response.status === 401 || response.status === 403) {
                navigate('/login');
            }
            else if (response.status === 500) {
                navigate('/login');
            }

            setLoading(false);
            setdataLoaded(true);
        }

        if (!dataLoaded) {
            setLoading(true);
            getSignatures();
        }

    }, [navigate, dataLoaded]);

    async function getEmailsLog() {
        if (!dataEmailLogLoaded) {
            setLoading(true);
            const response = await apiPrivate().get(`${baseUrl}email-status/sendgrid-email-log/${id}`, { validateStatus: () => true });

            if (response.status === 200) {
                const { data } = response;
                console.log("data", data);
                setEmailsLog(data);
            }

            setLoading(false);
            setDataEmailLogLoaded(true);
        }
    }

    async function cancelBatchGroup() {
        setLoading(true);
        const response = await apiPrivate().put(`${baseUrl}batch/cancel-batchgroup-by-user/${id}`, { validateStatus: () => true });

        if (response.status === 200) {
            toast("Evento cancelado com sucesso!", {
                type: 'success',
                autoClose: 2000,
                theme: 'colored'
            });
            navigate('/signs');
        }
        else if (response.status === 400 || response.status === 401 || response.status === 403 || response.status === 500) {
            toast("Falha ao cancelar o evento, contate o administrador do sistema!", {
                type: 'error',
                autoClose: 2000,
                theme: 'colored'
            });

        }


        setLoading(false);
    }


    async function finishBatchGroup() {
        setLoading(true);
        const response = await apiPrivate().put(`${baseUrl}batch/finish-batchgroup-by-user/${id}`, { validateStatus: () => true });

        if (response.status === 200) {
            toast("Evento finalizado com sucesso!", {
                type: 'success',
                autoClose: 2000,
                theme: 'colored'
            });
            navigate('/signs');

        }
        else if (response.status === 400 || response.status === 401 || response.status === 403 || response.status === 500) {
            toast("Falha ao finalizar o evento, contate o administrador do sistema!", {
                type: 'error',
                autoClose: 2000,
                theme: 'colored'
            });

        }
        setLoading(false);
    }

    async function sendEmail(signerId) {
        setLoading(true);
        const response = await apiPrivate().post(`${baseUrl}batch/resend-emails/${id}/?signerId=${signerId}`, { validateStatus: () => true });

        if (response.status === 200) {
            toast("Email enviado!", {
                type: 'success',
                autoClose: 2000,
                theme: 'colored'
            });

        }
        else if (response.status === 400 || response.status === 401 || response.status === 403 || response.status === 500) {
            toast("Falha ao reenviar o email, contate o administrador do sistema!", {
                type: 'error',
                autoClose: 2000,
                theme: 'colored'
            });

        }


        setLoading(false);

    }

    function dateFormat(date) {
        if (!date) {
            return "";
        }

        return `${new Date(date).toLocaleDateString()} - ${new Date(date).toLocaleTimeString()}`;
    }

    function translateEventName(eventName) {
        switch (eventName) {
            case "processed":
                return "processado";
            case "delivered":
                return "entregue";
            case "open":
                return "aberto";
            case "click":
                return "clicou no link";
            case "spamreport":
                return "reportado como span";
            case "dropped":
                return "excluído";
            default:
                return eventName;

        }

    }




    return (
        <AuthProvider>
            <LogoLayout hasExit={true}>
                {loading && (
                    <div className="flex flex-col items-center justify-center mt-5">
                        <svg className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                )}

                {!loading && (
                    <div className="text-center">
                        <Tabs>

                            <Tab label="Assinantes">

                                <div className="py-4">
                                    {
                                        signatures &&
                                        <>

                                            <h2 className="text-lg font-medium mb-2">Assinantes do Envelope: <b>{signatures.batchGroupName}</b></h2>
                                            <div className="relative rounded-lg border border-gray-200 shadow-md m-3 overflow-x-auto text-center">
                                                <table className={'w-full border-collapse bg-white text-left text-sm text-gray-500 z-10 ' + classLoading}>
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Status</th>
                                                            <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Nome</th>
                                                            <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Email</th>
                                                            <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">CPF</th>
                                                            <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Papel</th>
                                                            <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Data Assinatura</th>
                                                            {
                                                                canSendEmail &&
                                                                <th scope="col" className="px-1 py-4 font-medium text-gray-900 text-center">Enviar Email</th>

                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody className={'divide-y divide-gray-100 border-t border-gray-100'}>
                                                        {
                                                            signatures &&
                                                            signatures.data.map(signature => (
                                                                <tr key={signature.signerId} className="hover:bg-gray-50">
                                                                    <td className="flex gap-3 px-2 py-4 font-normal text-gray-900">
                                                                        <div className="text-sm">
                                                                            <div className="font-medium text-gray-700">
                                                                                {signature.allDocumentsSigned &&
                                                                                    <>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-green-500">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                                                                                        </svg>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    !signature.allDocumentsSigned &&
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-500 opacity-50">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                                    </svg>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 py-4">
                                                                        <div className="text-sm">
                                                                            <div className="font-medium text-gray-700">{signature.signerName}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 py-4">
                                                                        <div className="text-sm">
                                                                            <div className="text-gray-700">{signature.signerEmail}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 py-4">
                                                                        <div className="text-sm">
                                                                            <div className="text-gray-700">{signature.vatNumber}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 py-4">
                                                                        <div className="text-sm">
                                                                            <div className="text-gray-700">{signature.role}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="px-2 py-4">
                                                                        <div className="text-sm">
                                                                            {
                                                                                signature.signedAt &&
                                                                                <span className="ml-1">{dateFormat(signature.signedAt)}</span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        canSendEmail &&
                                                                        <td className="px-2 py-4">
                                                                            {
                                                                                signature.canSendEmail &&
                                                                                <a title='envia email para o assinante' className='cursor-pointer' onClick={() => sendEmail(signature.signerId)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 hover:text-sky-700 cursor-pointer m-auto" >
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                                                                    </svg>

                                                                                </a>
                                                                            }
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <div className='border-t-2'></div>

                                            </div>
                                        </>
                                    }
                                </div>

                            </Tab>

                            <Tab label="Histórico de Envio de Emails" visible={canSendEmail}>
                                <div className="py-4">
                                    {
                                        emailsLog && signatures && canSendEmail &&
                                        <>
                                            <h2 className="text-lg font-medium mb-2">Histórico de envios de emails do envelope: <b>{signatures.batchGroupName}</b></h2>
                                            <div className='mt-10'>

                                                <>

                                                    <div>
                                                        <h2 className="text"></h2>
                                                    </div>

                                                    <div className="relative rounded-lg border border-gray-200 shadow-md m-3 overflow-x-auto text-center">
                                                        <table className={'w-full border-collapse bg-white text-left text-sm text-gray-500 z-10 ' + classLoading}>
                                                            <thead className="bg-gray-50">
                                                                <tr>
                                                                    <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">Email</th>
                                                                    <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">data</th>
                                                                    <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">IP</th>
                                                                    <th scope="col" className="px-3 py-4 font-medium text-gray-900 text-start">ação</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className={'divide-y divide-gray-100 border-t border-gray-100'}>
                                                                {
                                                                    emailsLog != null &&
                                                                    emailsLog.map(log => (
                                                                        <tr key={log.id} className="hover:bg-gray-50">
                                                                            <td className="px-2 py-4">
                                                                                <div className="text-sm">
                                                                                    <div className="font-medium text-gray-700">{log.email}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="px-2 py-4">
                                                                                <div className="text-sm">
                                                                                    <div className="font-medium text-gray-700">{dateFormat(log.createdAt)}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="px-2 py-4">
                                                                                <div className="text-sm">
                                                                                    <div className="text-gray-700">{log.ip}</div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="px-2 py-4">
                                                                                <div className="text-sm">
                                                                                    <div className="text-gray-700">{translateEventName(log.event)}</div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div className='border-t-2'></div>

                                                    </div>
                                                </>
                                            </div>
                                        </>
                                    }
                                </div>
                            </Tab>


                        </Tabs>


                        <div class="fixed top-right-10 p-4">
                            <div class="flex space-x-4">
                                {
                                    canCancelBatchGroup &&
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 border border-blue-700 rounded"
                                        onClick={() => cancelBatchGroup()} >
                                        Cancelar
                                    </button>
                                }
                                {
                                    canFinishBatchGroup &&
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 border border-blue-700 rounded"
                                        onClick={() => finishBatchGroup()} >
                                        Finalizar
                                    </button>
                                }
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 border border-blue-700 rounded"
                                    onClick={() => navigate('/signs')} >
                                    Voltar
                                </button>
                            </div>
                        </div>





                        {/* <div class="flex">
                            {
                                canCancelBatchGroup &&

                                <div className="flex flex-1 justify-end px-3">
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 border border-blue-700 rounded"
                                        onClick={() => cancelBatchGroup()} >
                                        Cancelar
                                    </button>
                                </div>
                            }

                            {
                                canFinishBatchGroup &&
                                <div className="flex flex-1 justify-end px-3">
                                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 border border-blue-700 rounded"
                                        onClick={() => finishBatchGroup()} >
                                        Finalizar
                                    </button>
                                </div>
                            }

                            <div className="flex flex-1 justify-end px-3">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 border border-blue-700 rounded"
                                    onClick={() => navigate('/signs')} >
                                    Voltar
                                </button>
                            </div>
                        </div> */}

                    </div >
                )}
            </LogoLayout>
        </AuthProvider>
    );
}


export default SignaturesListViewPage;

