const SuccessMessage = ({ title, msg }) => (

    <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 mt-5" role="alert">
        <span className="font-medium">{title}</span>
        <br></br>{msg}
    </div>
)
export default SuccessMessage;


