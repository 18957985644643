import React, { useRef, useState, useCallback, useMemo } from "react";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import { getToken, verifyToken } from "../Services/verify-token-service";
import { validarCPF } from "../Utils/cpf-validator";
import { getApiErrors } from "../Utils/get-api-error";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";


const scrollDown = (delay = 1000) => {
    setTimeout(() => {
        const element = document.getElementById('root');
        window.scrollTo(0, element.scrollHeight);
    }, delay);
}


const CustomInput = ({ inputValues, setInput, inputsNumber, inputRefs, cpf = false }) => {
    const handleChange = useCallback((event, index) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = event.target.value;
        setInput(newInputValues);
    }, [inputValues, setInput]);

    const handlePaste = useCallback((event) => {
        event.preventDefault();
        const paste = event.clipboardData.getData('text').toUpperCase();
        const newInputValues = Array.from({ length: inputsNumber }, (_, i) => paste[i] || "");
        setInput(newInputValues);
    }, [inputsNumber, setInput]);

    const handleNextOrPreviousStep = useCallback((event, currentIndex) => {
        const cleanInputsRefs = inputRefs.current.filter(x => !!x);
        const newValue = event.target.value;
        if (!newValue) {
            if (currentIndex === 0) return;
            let prevStep = currentIndex - 1;
            cleanInputsRefs[prevStep].focus();
        } else {
            if (inputRefs.current.length - 1 === currentIndex) return;
            let nextStep = currentIndex + 1;           
            cleanInputsRefs[nextStep].focus();
        }
    }, [inputRefs]);

    const handleInputChange = useCallback((event, index) => {
        handleChange(event, index);
        handleNextOrPreviousStep(event, index);
    }, [handleChange, handleNextOrPreviousStep])

    const handleKeyPressOnInput = useCallback((event, index) => {
        if ((!!inputValues[index] && !!event.target.value) && inputValues[index] === event.key) {
            handleNextOrPreviousStep(event, index);
        }
        if (event.code === 'Backspace' && !event.target.value) {
            handleNextOrPreviousStep(event, index);
        }

    }, [handleNextOrPreviousStep, inputValues]); 

    const inputClasses = 'mx-0.5 lg:mx-1 border text-xs font-thin lg:text-base h-8 w-8 lg:h-10 lg:w-10 text-center form-control rounded uppercase';
    const inputClassesToken = 'mx-0.5 lg:mx-1 border text-xs font-thin lg:text-base h-9 w-9 lg:h-10 lg:w-10 text-center form-control rounded uppercase';
    const dotClasses = 'hidden lg:inline lg:mx-1 w-0.5 h-0.5 rounded-full bg-black mt-5'

    const inputElements = useMemo(() => {
        if (cpf) { 
            return inputValues.map((value, index) => {       
                if (index !== 0 && index % 3 === 0)
                    return (
                        <React.Fragment key={index}>
                            <span className={dotClasses}></span>
                            <input
                                value={value}
                                className={inputClasses}
                                type="text"
                                maxLength={1}
                                ref={ref => inputRefs.current[index] = ref}
                                onFocus={(e) => inputRefs.current[index].select()}
                                onPaste={(e) => handlePaste(e)}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyUp={(e) => handleKeyPressOnInput(e, index)}

                        />  
                        </React.Fragment>
                    )
                
                else if (index === 9)
                    return (
                        <React.Fragment key={index}>
                            <span className={dotClasses} ></span>
                            <input
                                value={value}
                                className={inputClasses}
                                type="text"
                                maxLength={1}
                                ref={ref => inputRefs.current[index] = ref}
                                onFocus={(e) => inputRefs.current[index].select()}
                                onPaste={(e) => handlePaste(e)}
                                onChange={(e) => handleInputChange(e, index)}
                                onKeyUp={(e) => handleKeyPressOnInput(e, index)}
                        />  
                        </React.Fragment>
                    )
                else 
                    return (
                        <input
                            key={index}
                            value={value}
                            className={inputClasses}
                            type="text"
                            maxLength={1}
                            ref={ref => inputRefs.current[index] = ref}
                            onFocus={(e) => inputRefs.current[index].select()}
                            onPaste={(e) => handlePaste(e)}
                            onChange={(e) => handleInputChange(e, index)}
                            onKeyUp={(e) => handleKeyPressOnInput(e, index)}
                        />        
                    )  
            })

        }

        return inputValues.map((value, index) => (
            <input
                key={index}
                value={value}
                className={inputClassesToken}
                type="text"
                maxLength={1}
                ref={ref => inputRefs.current[index] = ref}
                onFocus={(e) => inputRefs.current[index].select()}
                onPaste={(e) => handlePaste(e)}
                onChange={(e) => handleInputChange(e, index)}
                onKeyUp={(e) => handleKeyPressOnInput(e, index)}
            />
        ));
    }, [inputValues, handlePaste, handleInputChange, inputRefs, cpf, handleKeyPressOnInput]);


    return inputElements;
}


const TokenInput = ({ 
    handleButtonTokenClick, 
    loading = false, 
    signature = false, 
    validatedToken = false, 
    validatedCpf = false,
    setValidatedToken,
    setValidatedCpf
 
}) => {

    const cpfLength = 11;
    const tokenLength = 6;

    const tokenInputsRefs = useRef([]);
    const [inputToken, setToken] = useState(Array.from({ length: tokenLength }, () => ""));

    const cpfInputsRefs = useRef([]);
    const [inputCpf, setCpf] = useState(Array.from({ length: cpfLength }, () => ""));

    const [validateEnabled, setValidateEnabled] = useState(false);


    const getValues = useCallback(() => ({
        token: inputToken.join(''),
        cpf: signature ? inputCpf.join('').replace('.', '').replace('-', '') : null,
        signature
    }), [inputCpf,  inputToken, signature]);


    // useEffect(() => {
    //     console.log('TESTE EFFECT');
    // }, [inputCpf,  inputToken, signature]);

    useEffect(() => {

        const cpfHasLength = values => !!values.cpf && values.cpf.length === cpfLength;
        const tokenHasLength = values => values.token.length === tokenLength;
        const values = getValues();

        if (!!values.signature) {
            if (cpfHasLength(values)) {
                if (validarCPF(values.cpf)) {
                    if (tokenHasLength(values)) {
                        setValidatedToken(true);
                        setValidatedCpf(true);  
                        setValidateEnabled(true);
                        return;
                    } 
                }
                else {
                    setValidatedToken(true);
                    setValidatedCpf(false);  
                    setValidateEnabled(false);
                    return;
                }
            }
        }
        else if (tokenHasLength(values)) {
            setValidatedCpf(true);
            setValidatedToken(true);
            setValidateEnabled(true);
            return;
        } 
      
        setValidatedCpf(true);
        setValidatedToken(true);
        setValidateEnabled(false); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues])


    const handleValidateTokenRequest = () => {
        handleButtonTokenClick(getValues(), () => setValidateEnabled(true));
        setValidateEnabled(false);
        // setTimeout(() => {
        //     setValidateEnabled(true);
        // }, 6000);
    }

    const cleanCpf = () => {

        setCpf(Array.from({ length: cpfLength }, () => ""));
        cpfInputsRefs.current[0].select();
        setValidateEnabled(false);    
    }


    const cleanToken = () => {
        setToken(Array.from({ length: tokenLength }, () => ""));
        tokenInputsRefs.current[0].select();
        setValidateEnabled(false);
    }

    return (

        <div className="flex flex-col items-center justify-center mt-3">

            {signature && (
                <>
                    <p className="mx-auto mt-1 max-w-2xl text-lg text-gray-600">
                        Insira seu CPF
                    </p>
                    <div id="otp-cpf" className="flex flex-row justify-center text-center px-2 mt-3 mb-1">
                        <CustomInput inputsNumber={cpfLength} inputValues={inputCpf} setInput={setCpf} inputRefs={cpfInputsRefs} cpf />  
                    </div>

                    {(!validatedCpf) &&
                        <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            CPF inválido <button className="font-medium text-red-500 hover:text-red-200 underline ml-1" onClick={cleanCpf}>clique para limpar.</button>
                        </div>
                    } 
                </>
            )}

            <p className="mx-auto mt-2 max-w-2xl text-lg text-gray-600">
                Insira o token enviado ao seu e-mail
            </p>
            <div id="otp" className="flex flex-row justify-center text-center px-2 mt-3 mb-2">
                <CustomInput inputsNumber={tokenLength} inputValues={inputToken} setInput={setToken} inputRefs={tokenInputsRefs} />    
            </div>
            
            {(!validatedToken) &&
                <div className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    Token inválido <button className="font-medium text-red-500 hover:text-red-200 underline ml-1" onClick={cleanToken}>clique para limpar.</button>
                </div>
            } 
 

            <button disabled={!validateEnabled} className="py-2 px-4 flex items-center gap-2 text-white font-bold bg-green-400 mt-3 rounded disabled:opacity-50" onClick={handleValidateTokenRequest}>
                Validar
                {loading && (
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" ></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                )}
            </button>
        </div>
    )
}

const CountDownButton = ({ timeout = 240, onClick, signature = false, requested = false, }) => {

    const [secondsDisabledTimeout, setSecondsDisabledTimeout] = useState(timeout);
    // const [isDisabled, setIsDisabled] = useState(false);
    const [showCountdown, setShowCountdown] = useState(false);

    useEffect(() => {
        if (!!showCountdown) {
            setSecondsDisabledTimeout(timeout);

            const interval = setInterval(() => {
                setSecondsDisabledTimeout(time => time - 1);
            }, 1000);
    
            setTimeout(function () {
                clearInterval(interval);
                setShowCountdown(false);

            }, timeout * 1000);

            return () => {
                clearInterval(interval);
                setShowCountdown(false);

            }
        }
    }, [showCountdown, timeout]);

    const onClickInternal = () => {
        setShowCountdown(true);
        if (!!onClick) {
            onClick();
        }
    }


    return (
        <div className={`flex flex-col items-center justify-center h-18 `}>

            {!showCountdown && (<p className={'mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600'}>
                Solicite o token de acesso, o mesmo sera enviado ao email cadastrado
            </p>) }

            {
            (requested && !showCountdown && secondsDisabledTimeout <= 0) && (<p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-red-600">                
                    Token expirado! Clique no botão abaixo para solicitar um novo token
            </p>)
            }
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-5 disabled:opacity-25" onClick={onClickInternal} disabled={showCountdown}>
                {signature ? (requested ? "Reenviar token para assinar" : 'Visualizar Documentos') : (requested ? "Reenviar token" : 'Enviar token')}
            </button>
        </div>
    );
}


export const ApprovalTokenValidation = ({ hash, secondsRequestTokenDisabled, validateCallback, signature = false }) => {


    const navigate = useNavigate();

    // const [loadingRequestToken, setLoadingRequestToken] = useState(false);

    const [showResults, setShowResults] = React.useState(false);
    const [loadingValidate, setLoadingValidate] = useState(false);
    const [requestValidateError, setRequestValidateError] = useState(false);

    
    const [validateSuccess, setValidateSuccess] = useState(false);
    const [errorRequest, setErrorRequest] = useState(false);

    const [tokenValid, setTokenValid] = useState(true);
    const [cpfValid, setCpfValid] = useState(true);


    const getValidationToken = () => {

        if (!hash) {
            navigate('/');
            return;
        }


        getToken(hash, signature).then(
            (result) => {
                if (result.success) {
                    setErrorRequest(false);
                    setShowResults(true);
                    scrollDown(0);  

                }
                else {
                    setErrorRequest(true);
                }
            },
            (error) => {
                console.log('ERRO', error);
                setErrorRequest(true);
                setShowResults(false);
            }
        )
    };

    const handleValidateTokenClick = (obj, callback) => {
        setLoadingValidate(true);

        verifyToken(obj, signature).then((result) => { 
                if (result.success) {
                    setValidateSuccess(true);
                    setRequestValidateError(false);
                    scrollDown(0);
                    setTimeout(() => {
                        //setIsDisabled(false);
                        validateCallback(hash, obj.token, callback);
                        setLoadingValidate(false);
                        
                    }, 2000);

                } else {
                    setLoadingValidate(false);
                    setRequestValidateError(true);
                    scrollDown(0);
                    setTimeout(() => {
                        setRequestValidateError(false);
                    }, 5000);

                    const apiErrors = getApiErrors(result);

                    if (apiErrors.hasValues) {
                        const error = apiErrors.getFirst();
                        if (error.errorCode === 'cpf') {
                            setCpfValid(false);
                        }
                        else {
                            setTokenValid(false);
                        }
                    }
                }
        },
        (error) => { 
            setLoadingValidate(false);
            console.log(error);
        });
    };


    return (

        <div className="text-center px-12">
                {!validateSuccess && <CountDownButton onClick={getValidationToken} signature={signature} requested={showResults} />}
                <div className={`transition duration-500${!showResults ? " transform scale-75 opacity-0" : ""}`}>
                    {showResults && <TokenInput 
                        validatedToken={tokenValid} 
                        validatedCpf={cpfValid}
                        setValidatedCpf={setCpfValid}
                        setValidatedToken={setTokenValid} 
                        handleButtonTokenClick={handleValidateTokenClick} 
                        loading={loadingValidate} 
                        signature={signature} 
                        />
                    }
                    {validateSuccess && <SuccessMessage title={'Token válido'} msg={'Aguarde alguns instantes ... '} />}
                    {(!showResults && errorRequest) && <ErrorMessage title={'Erro ao solicitar token.'} msg={'Não foi possível solicitar token.'} />}
                    {(requestValidateError && !tokenValid) && <ErrorMessage title={'Erro ao validar o token.'} msg={'Token inválido'} />}
                    {(requestValidateError && !cpfValid) && <ErrorMessage title={'Erro ao validar o CPF.'} msg={'CPF inválido'} />}     
                </div>
            
        </div>

    )
}
